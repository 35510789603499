'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import LocalStorage from 'store';

import Conditions from '../Conditions.react';
import Prescription from '../Prescription.react';
import EnergyCalc from '../EnergyCalc.react';
import EditorModal from '../../../Modals/EditorModal.react';
import Working from '../Working.react';

import AuthStore from '../../../../../stores/AuthStore';
import UserStore from '../../../../../stores/UserStore';
import { getConfig } from '../../../../../utils/Env';
import Analytics from '../../../../../utils/Analytics';

import { 
         getParamsForProfile,
         shouldAdjustMacrosForVeggies,
         adjustMacrosForVeggies,
         resetMacroRxToConditionDefault,
         isPrescriptionAdjustedForVeggies,
         adjustMacrosForPescatarians,
         isPrescriptionAdjustedForPescatarians,
         shouldAdjustMacrosForPescatarians} from '../../../../utils/Patients';

import { processVirtualPlans } from '../../../../../utils/Plans';

import modalStyles from '../../../../../jsx-styles/modals';

import './CreatePatientModal.scss';

export default class PrescriptionEditorModal extends Component {
    static propTypes = {
        includeConditions: PropTypes.bool,
        includeEnergy: PropTypes.bool,
        includeRx: PropTypes.bool,
    };

    static defaultProps = {
        includeConditions: true,
        includeEnergy: true,
        includeRx: true,
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const user = UserStore.getUser();

        this.state = {
            user,
            patient: JSON.parse(JSON.stringify(props.patient)),
            index: 0,
            working: false,

            dirty: false,
        };
    }

    savePatient = (patient) => {
        // Omit the stuff from the patient record that's read-only. E.g.: we can't change the uuid
        const { uuid, links, created, invite_sent, invite_accepted, preferences, recommendations, active, activated_date, deactivated_date,
                ...rest } = patient;

        this.setState({working: true, error: null});

        // Save the record
        return AuthStore.fetch(getConfig('users_api') + patient.links.self.href, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=patient/1'},
            body: JSON.stringify({preferences, ...rest}),
        });
    }

    nextPage = (page, index) => {
        let { patient } = this.state;

        if (!page.ref) {
            return;
        }
        const ref = this.refs[page.ref];

        // Validate of the page passed, mutation of patient is complete, go to the next page.
        const error = (ref.validate && ref.validate()) || true;

        if (error !== true) {
            this.setState({error});

            return;
        }

        let dirty = ref.isDirty && ref.isDirty();

        // Mutate the patient object with the values from the form.
        patient = ref.mutate(patient);

        // Do we need to skip saving the patient?
        if (page.dont_save) {
            dirty && page.onSavePage && page.onSavePage();

            this.setState({patient, index: index + 1, error: null, dirty, editMode: false});
            return;
        }

        // Save the patient
        this.savePatient(patient).then(
            response => {
                this.setState({working: false, patient: response, index: index + 1, error: null, dirty: false, editMode: false});

                if (this.scrollable) {
                    this.scrollable.scrollTop = 0;
                }

                dirty && page.onSavePage && page.onSavePage();
            },
            error => {
                this.setState({working: false, error: (error && error.message) || null});
            }
        );
    }

    prevPage = (page, index) => {
        let { patient } = this.state;
        index--;

        if (index < 0) {
            index = 0;
        }

        if (page.ref) {
            const ref = this.refs[page.ref];

            // Mutate the patient object with the values from the form.
            patient = ref.mutate(patient);
        }

        this.setState({index, error: null, patient, editMode: false});

        if (this.scrollable) {
            this.scrollable.scrollTop = 0;
        }
    }

    setStoredPlans = (patient, virtual, lastGenParams) => {
        const user = UserStore.getUser();
        const expires = moment().add(7, 'day').format();
        const key = ['patient-recommended-plans', patient.uuid, user.uuid].join('-');

        virtual = virtual.map(p => p.uuid);

        LocalStorage.set(key, {virtual, expires, lastGenParams});
    }

    populatePatientPlans = (patient) => {
        const genParams = getParamsForProfile(patient, 'generator');

        // Stringify before we start mucking with the parameters
        const lastGenParams = JSON.stringify(genParams);
        genParams.size = 1;

        return new Promise((accept, reject) => {
            AuthStore.fetch(getConfig('recipe_api') + '/plan-generator', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=vplan/parameters/1'},
                body: JSON.stringify(genParams),
            }).then(
                results => {
                    // No results back?
                    if (!(results && results.elements && results.elements.length)) {
                        return reject('Unable to build a meal plan that fits these nutrition parameters');
                    }

                    let virtual = processVirtualPlans(results.elements);

                    this.setStoredPlans(patient, virtual, lastGenParams);

                    accept(virtual);
                },
                error => reject(error)
            );
        });
    }

    onFinish = (page, index) => {
        let { patient } = this.state;

        if (page.ref) {
            const ref = this.refs[page.ref];
            const error = (ref.validate && ref.validate()) || true;

            if (error !== true) {
                this.setState({ error });
                return;
            }

            // Mutate the patient object with the values from the form BEFORE running onFinish logic.
            patient = ref.mutate(patient);
        }

        const { diets } = patient.preferences;

        const shouldBeAdjustedVeggies = shouldAdjustMacrosForVeggies(patient, diets);
        const isAdjustedVeggies = isPrescriptionAdjustedForVeggies(patient.prescriptions);
        const shouldBeAdjustedPescatarians = shouldAdjustMacrosForPescatarians(patient, diets);
        const isAdjustedPescatarians = isPrescriptionAdjustedForPescatarians(patient.prescriptions);

        // Is this one of the conditions we're modifying?
        if (shouldBeAdjustedVeggies && !isAdjustedVeggies) {
            this.context.confirm(
                <div>
                    <p>We recommend the following macro breakdown for {diets[0]} {patient.practice_type === 'dietetics' ? 'patients' : 'clients'} for optimal results: 15% pro, 35% fat, and 50% carb.</p>
                    <p>Would you like us to recalculate the {patient.practice_type === 'dietetics' ? 'nutrition prescription' : 'nutrient profile'} with these numbers?</p>
                    <p>You can see {patient.practice_type === 'dietetics' ? 'and edit' : ''} them before saving.</p>
                </div>,
                accept => {
                    patient.prescriptions = adjustMacrosForVeggies(patient.prescriptions);
                    this.setState({ patient }, () => {
                        this.finish(page, index);
                    });
                },
                reject => {
                    this.finish(page, index);
                },
                {
                    rejectText: 'Keep Prescription',
                    acceptText: 'Recalculate'
                }
            );

            return;
        }

        if (shouldBeAdjustedPescatarians && !isAdjustedPescatarians) {
            this.context.confirm(
                <div>
                    <p>We recommend the following macro breakdown for {diets[0]} {patient.practice_type === 'dietetics' ? 'patients' : 'clients'} for optimal results: 20% pro, 35% fat, and 45% carb.</p>
                    <p>Would you like us to recalculate the {patient.practice_type === 'dietetics' ? 'nutrition prescription' : 'nutrient profile'} with these numbers?</p>
                    <p>You can see and edit them before saving.</p>
                </div>,
                accept => {
                    patient.prescriptions = adjustMacrosForPescatarians(patient.prescriptions);
                    this.setState({ patient }, () => {
                        this.finish(page, index);
                    });
                },
                reject => {
                    this.finish(page, index);
                },
                {
                    rejectText: 'Keep Prescription',
                    acceptText: 'Recalculate'
                }
            );

            return;
        }

        if ((!shouldBeAdjustedVeggies && isAdjustedVeggies) || (!shouldBeAdjustedPescatarians && isAdjustedPescatarians)) {
            this.context.confirm(
                <div>
                    <p>This {patient.practice_type === 'dietetics' ? 'patient' : 'client'} has macros set optimally for vegetarians &amp; vegans. </p>
                    <p>Would you like us to recalculate the {patient.practice_type === 'dietetics' ? 'nutrition prescription' : 'nutrient profile'} to the default non-vegan/vegatarian settings?</p>
                    <p>You can see {patient.practice_type === 'dietetics' ? 'and edit' : ''} them before saving.</p>
                </div>,
                accept => {
                    patient.prescriptions = resetMacroRxToConditionDefault(patient.prescriptions);
                    this.setState({ patient }, () => {
                        this.finish(page, index);
                    });
                },
                reject => {
                    this.finish(page, index);
                },
                {
                    rejectText: 'Keep Prescription',
                    acceptText: 'Recalculate'
                }
            );

            return;
        }

        this.finish(page, index);
    }

    finish = async (page, index) => {
        let { closeModal, onChangePatient } = this.props;
        let { patient } = this.state;

        // Save the patient.
        try {
            const response = await this.savePatient(patient);
            page.onSavePage && page.onSavePage();
            onChangePatient(response);

            await this.populatePatientPlans(patient);

            closeModal();
        } catch (error) {
            this.setState({working: false, error: (error && error.message) || error || 'unknown error'});
        }
    }

    closeModal = (page) => {
        const { dirty } = this.state;
        const { closeModal } = this.props;

        if (dirty || (page && page.ref && this.refs[page.ref].isDirty && this.refs[page.ref].isDirty())) {
            this.context.confirm(
                <div className="confirm-discard-changes">
                    <h6>Discard changes?</h6>
                    <p>You&apos;ve made changes to this patient, are you sure you want to discard those changes?</p>
                </div>,
                closeModal,
                () => false,
                {
                    acceptText: 'Discard Changes'
                }
            );

            return;
        }

        closeModal();
    }

    getPages = (patient) => {
        const pages = [];
        const { patient: originalPatient, includeConditions, includeEnergy, includeRx } = this.props;
        const { practice = {} } = this.state.user;


        if (includeConditions) {
            pages.push({
                title: patient.practice_type === 'dietetics'
                       ? <span>{patient.first_name}&apos;s Health Condition</span>
                       : <span>{patient.first_name}&apos;s Lifestyle Goal</span>,
                component: <Conditions ref="conditions" patient={patient} originalPatient={originalPatient}/>,
                ref: 'conditions',
                skippable: false,
                dont_save: true,
                onSavePage: () => Analytics.updatePatientCondition({
                    'Patient UUID': patient.uuid
                }),
            });
        }

        if (includeEnergy) {
            pages.push({
                title: <span>Calculate {patient.first_name}&apos;s Energy Needs</span>,
                component: <EnergyCalc ref="ecalc" patient={patient} />,
                ref: 'ecalc',
                skippable: false,
                dont_save: true,
                onSavePage: () => Analytics.updatePatientEnergy({
                    'Patient UUID': patient.uuid
                }),
            });
        }

        if (includeRx) {
            pages.push({
                title: patient.practice_type === 'dietetics'
                       ? <span>{patient.first_name}&apos;s Nutrition Prescription</span>
                       : <span>{patient.first_name}&apos;s Nutrient Profile</span>,
                component: <Prescription ref="prescription" patient={patient} />,
                ref: 'prescription',
                skippable: false,
                onSavePage: () => Analytics.updatePatientNutrition({
                    'Patient UUID': patient.uuid
                }),
            });
        }

        return pages;
    }

    togglePageEditMode = (page, i) => {
        if (!(page.ref && this.refs[page.ref])) {
            return;
        }

        const ref = this.refs[page.ref];

        this.setState({editMode: ref.toggleEditMode()});
    }

    render() {
        const { patient } = this.state;
        const { working, index, error, editMode } = this.state;

        const pages = this.getPages(patient);

        return (
            <Modal isOpen={true}
                onRequestClose={() => this.closeModal(pages[index])}
                closeModal={() => this.closeModal(pages[index])}
                className="create-patient-modal editor-modal"
                contentLabel="Update Patient"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="create-patient-container editor-modal-container" data-working={working}>
                    <header className="modal-header">
                        <h2>{pages[index].title}</h2>
                        <button className="close-btn" onClick={() => this.closeModal(pages[index])}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div>
                        {pages.map((page, i) => {
                            return (
                                <div key={i}>
                                    <div className="editor-scrollable" data-active={i === index} ref={el => this.scrollable = el}>
                                        <section className="wizard-page" data-active={i === index}>
                                            {i === index ? page.component : null}
                                        </section>
                                    </div>
                                    {i === index ?
                                        <footer className="modal-footer">
                                            <div className="error-msg" data-active={error ? true : false}>{error || '.'}</div>

                                            {i === 0
                                                ? <button className="prev" onClick={() => this.closeModal(page)}>Cancel</button>
                                                : <button className={(page.skippable || page.editable) ? "prev-with-skip" : "prev"}
                                                    onClick={() => this.prevPage(page, i)}>
                                                        Back
                                                  </button>
                                            }

                                            {page.skippable ?
                                                <button className="skip" onClick={() => this.nextPage(page, i)}>Skip</button>
                                            : null}

                                            {page.editable ?
                                                <button className="skip" onClick={() => this.togglePageEditMode(page, i)}>
                                                    {editMode ? 'Save' : 'Edit'}
                                                </button>
                                            : null}

                                            {i < (pages.length - 1)
                                                ? <button className="next" onClick={() => this.nextPage(page, i)}>Next</button>
                                                : <button className="next" onClick={() => this.onFinish(page, i)}>Finish</button>
                                            }

                                            <div className="page-dots">
                                                {Object.keys(Array.apply(null, {length: pages.length})).map(j => {
                                                    return <i className="icon-bullet" key={j} data-active={i == j} />
                                                })}
                                            </div>
                                        </footer>
                                    : null}
                                </div>
                            );
                        })}
                    </div>

                    {working ?
                        <Working title={`Saving ${patient.first_name}`}
                            message={`Saving ${patient.first_name}’s preferences and nutrition prescription`} />
                    : null}

                </div>

            </Modal>
        );
    }
}
